import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel,toDate } from '@core/utils/filter'

export default function purchasepaymentUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'order_id', label: 'ID', sortable: true },
    { key: 'order_no', label: '付款单编号', sortable: true },
    { key: 'applier_id', label: '申请人', sortable: true },
    // { key: 'pay_company_id', label: '付款主体ID   company_type = 2', sortable: true },
    // { key: 'pay_company_name', label: '付款主体', sortable: true },
    // { key: 'purchase_company_id', label: '采购主体ID   purchase_order.company_id', sortable: true },
    { key: 'bank_supplier_name', label: '供应商', sortable: true },
    { key: 'purchase_company_name', label: '采购主体', sortable: true },
    { key: 'purchase_order_no', label: '采购订单编号', sortable: true },
    // { key: 'type_id', label: '业务类型   purchase_order.type_id', sortable: true },
    // { key: 'pay_condition', label: '付款条件     purchase_order.first_pay_condition', sortable: true },
    // { key: 'express_ticket_type', label: '物流单据类型    express_ticket_type', sortable: true },
    // { key: 'delivery_no_erp', label: '收料ERP单号', sortable: true },
    // { key: 'invoice_type', label: '发票类型   purchase_order.invoice_type', sortable: true },
    // { key: 'tax_rate', label: '采购发票税率 tax_rate', sortable: true },
    // { key: 'is_invoice', label: '是否收到采购发票', sortable: true },
    // { key: 'total_pay', label: '应付金额    purchase_order.total_pay', sortable: true },
    // { key: 'payment_method', label: '付款方式     purchase_order.payment_method', sortable: true },
    // { key: 'payment_type', label: '付款类别     purchase_payment_type', sortable: true },
    // { key: 'pay_percent', label: '本次申请付款比例   根据payment_type获取purchase_order中对应数据', sortable: true },
    // { key: 'total_payed', label: '累计已付款金额(PO币)    purchase_order.total_paid', sortable: true },
    // { key: 'current_pay', label: '本次应付金额(PO币)      根据payment_type获取purchase_order中对应数据', sortable: true },
    { key: 'current_apply_pay', label: '申请付款金额', sortable: true },
    { key: 'total_payed_oa', label: 'OA已付款金额', sortable: true },
    // { key: 'current_remain', label: '本次付款后欠款余额(PO币)   等于purchase_order.total_pay -  purchase_order.total_paid - current_pay', sortable: true },
    { key: 'last_paydate', label: '最晚付款时间', sortable: true },
    // { key: 'pay_reason', label: '付款事由', sortable: true },

    // { key: 'bank_account_type', label: '银行账户类型     purchase_order.account_type   转换为中文', sortable: true },
    // { key: 'bank_is_outlands', label: '是否境外     purchase_order.is_outlands', sortable: true },
    // { key: 'bank_account_name', label: '收款人户名     purchase_order.account_name', sortable: true },
    // { key: 'bank_account_no', label: '收款人账号     purchase_order.account_no', sortable: true },
    // { key: 'bank_name', label: '收款行及支行名称    purchase_order.bank_name', sortable: true },
    // { key: 'bank_node_address', label: '银行地址    purchase_order.bank_node_address', sortable: true },
    // { key: 'bank_swift_code', label: '联行号    purchase_order.swift_code', sortable: true },
    // { key: 'remark', label: '备注', sortable: true },
    // { key: 'attachments', label: '相关附件', sortable: true },
    // { key: 'attachments_erpimage', label: 'ERP单据截图', sortable: true },
    // { key: 'attachments_bill', label: '发票附件上传', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'oa_node_status', label: 'OA节点状态', sortable: true },
    { key: 'expected_invoice_date', label: '预计发票到票时间', sortable: true, formatter:(value) =>  value ? toDate(value): value  },
    { key: 'invoice_date', label: '发票到票时间', sortable: true, formatter:(value) =>  value ? toDate(value): value   },
    { key: 'invoice', label: '发票敞口', formatter:(value,key,item) =>  item.oa_node_status === 3 ? 0 :item.current_apply_pay   },
    // { key: 'state', label: '状态', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },

    // { key: 'creator', label: '添加人ID', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const invoiceAmount = ref(0);
  const searchList = (ctx, callback) => {
    const condition = {...store.getters['purchasepayment/getCondition']}
    //删除一些条件
    condition.companyName = undefined
    condition.applierName = undefined
    const params = {
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
      purchase_order_id:config.purchase_order_id
    }
    Object.assign(params,condition)
    store
      .dispatch('purchasepayment/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        invoiceAmount.value = data.ext.invoiceAmount
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '付款申请单列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    invoiceAmount,

    refetchData,

    // Extra Filters
  }
}
